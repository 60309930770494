import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      baseOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0.08)',
              zeroLineWidth: 0,
            },
            ticks: {
              autoSkip: false,
              fontSize: 25,
              callback: value => value.emoji,
            },
          }],
        },
        tooltips: {
          callbacks: {
            title: (value, chartdata) => chartdata.labels[`${value[0].index}`].name,
          },
        },
      },
    }
  },
  mounted() {
    this.renderBarsChart()
  },
  computed: {
    computedChartData() { return this.chartdata },
  },
  methods: {
    renderBarsChart() {
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
            backgroundColor: 'rgba(42, 204, 204, 0.25)',
            borderColor: 'rgba(42, 204, 204, 1)',
            borderWidth: 1,
            data: this.chartdata,
          },
        ],
      }, { ...this.baseOptions, ...this.options })
    },
  },
  watch: {
    chartdata() {
      this.$data._chart.update()
      this.renderBarsChart()
    },
  },
}
